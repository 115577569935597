<template>
  <section class="main" v-if="!isLoading" ref="element">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(savePopup)">
        <div>
          <div class="columns is-multiline" v-if="!isLoading">
            <div class="column is-12">
              <h1 class="is-size-3"><b>Assessment Setup</b></h1>
            </div>
            <div class="column bar">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <h3 class="is-size-6">Assessment</h3>
                  </li>
                  <li>
                    <router-link :to="{ name: `newAssessment` }">
                      <h3 class="is-size-6">Assessment Setup</h3>
                    </router-link>
                  </li>
                  <li>
                    <h3 class="is-size-6 breadcrumb-item">
                      Show Assessment
                    </h3>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <div class="box">
                <AssessmentInformation :data="information" />

                <AssessmentSurvilance :data="survilance" />

                <AssessmentThankYouPage :data="thankYouPage" />

                <AssessmentPeriod :data="period" v-if="period.isUsePeriod" />

                <AssessmentListSection :data="listSection" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import AssessmentInformation from '@/components/V2/Assessment/Detail/AssessmentInformation'
import AssessmentSurvilance from '@/components/V2/Assessment/Detail/AssessmentSurvilance'
import AssessmentPeriod from '@/components/V2/Assessment/Detail/AssessmentPeriod'
import AssessmentThankYouPage from '@/components/V2/Assessment/Detail/AssessmentThankYouPage'
import AssessmentListSection from '@/components/V2/Assessment/Detail/AssessmentListSection'
import { mapActions } from 'vuex'

export default {
  components: {
    AssessmentInformation,
    AssessmentSurvilance,
    AssessmentPeriod,
    AssessmentThankYouPage,
    AssessmentListSection,
  },
  name: 'show-assessment',
  data() {
    return {
      // For Loading Page
      isLoading: false,

      // For Information Data
      information: {
        title: '',
        description: '',
      },

      // For survilance
      survilance: {
        screenCaptureTime: '',
        screenShotTime: '',
      },

      // For period
      period: {
        isUsePeriod: true,
        startPeriod: '',
        endPeriod: '',
      },

      // For Thank You Page
      thankYouPage: {
        title: '',
        description: '',
      },

      // For list section
      listSection: [],
    }
  },
  async created() {
    const response = await this.fetchAssessment(
      this.$route.params.newAssessmentId
    )

    this.information = response.data.information
    this.survilance = response.data.survilance
    this.period = response.data.period
    this.period.startPeriod =
      this.period.startPeriod == null ? '' : new Date(this.period.startPeriod)
    this.period.endPeriod =
      this.period.endPeriod == null ? '' : new Date(this.period.endPeriod)
    this.thankYouPage = response.data.thankYouPage

    this.isLoading = false
  },
  methods: {
    ...mapActions({
      fetchAssessment: 'newAssessment/fetchAssessment',
    }),

    // For succes alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
}
</script>
<style>
.prev {
  margin-left: 1.1% !important;
}
.button-next {
  margin-right: 1% !important;
}
</style>
