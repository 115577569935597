<template>
  <div style="margin-top: 31px;">
    <div class="columns is-multiline">
      <div class="column is-10 content-title">
        <h1 class="content-title__text">Section List</h1>
      </div>
      <div class="column is-2">
        <b-field grouped position="is-right">
          <router-link
            :to="'/new-assessment/add-section/' + $route.params.newAssessmentId"
          >
            <b-button
              class="icon-button"
              title="Add Section"
              style="color:#024886;"
            >
              Add Section
            </b-button>
          </router-link>
        </b-field>
      </div>
    </div>

    <b-table
      :loading="isLoadingTable"
      :data="sections.length > 0 ? sections : []"
    >
      <!-- For number -->
      <b-table-column
        field="index"
        width="10"
        numeric
        label="No"
        v-slot="props"
      >
        {{ props.index + 1 }}
      </b-table-column>

      <!-- For number -->
      <b-table-column field="index" width="650" label="Section" v-slot="props">
        {{ props.row.title }}
      </b-table-column>

      <!-- For action -->
      <b-table-column field="action" width="200" label="Action" v-slot="props">
        <router-link
          :to="{
            path: `/new-assessment/show-section/${$route.params.newAssessmentId}/${props.row.id}`,
          }"
        >
          <b-button
            class="icon-button"
            icon-left="eye"
            title="Detail Section"
          ></b-button>
        </router-link>
        &nbsp;
        <router-link
          :to="{
            path: `/new-assessment/edit-section/${$route.params.newAssessmentId}/${props.row.id}`,
          }"
        >
          <b-button
            class="icon-button"
            icon-left="pencil-alt"
            title="Edit Section"
          ></b-button>
        </router-link>
        <b-button
          class="icon-button"
          icon-left="copy"
          title="Duplicate Section"
          @click="duplicatePopup(props.row.id)"
        ></b-button>
        <b-button
          class="icon-button"
          icon-left="trash"
          title="Delete Section"
          @click="deletePopup(props.row.id)"
        ></b-button>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'assessment-list-section',
  data() {
    return {
      isLoadingTable: false,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions({
      fetchSectionsByAssessment: 'newSection/fetchSectionsByAssessment',
      deleteSectionData: 'newSection/deleteSection',
      duplicateSectionData: 'newSection/duplicateSection',
    }),

    async fetchData() {
      this.isLoadingTable = true
      try {
        await this.fetchSectionsByAssessment(this.$route.params.newAssessmentId)
      } catch (err) {
        this.danger(err)
      }
      this.isLoadingTable = false
    },

    // For duplicate popup
    duplicatePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Duplicate Section',
        message: `Are you sure want to duplicate this section?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, duplicate it!',
        type: 'is-hcc',
        onConfirm: () => this.duplicateSection(id),
      })
    },

    // For duplicate section
    async duplicateSection(id) {
      this.isLoading = true

      try {
        const response = await this.duplicateSectionData(id)

        if (response.data.status == 500) {
          this.danger('Assessment already in use')
        } else {
          this.success('Duplicate Section Success')
        }
      } catch (err) {
        this.danger(err)
      }

      this.fetchData()

      this.isLoading = false
    },

    // For delete popup
    deletePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Delete Section',
        message: `Are you sure want to delete this section?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, delete it!',
        type: 'is-danger',
        onConfirm: () => this.deleteSection(id),
      })
    },

    // For delete section
    async deleteSection(id) {
      this.isLoading = true

      try {
        const response = await this.deleteSectionData(id)

        if (response.data.status == 500) {
          this.danger('Section already in use')
        } else {
          this.success('Delete Section Success')
        }
      } catch (err) {
        this.danger(err)
      }

      this.fetchData()

      this.isLoading = false
    },

    // For success alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
  computed: {
    ...mapGetters({
      sections: 'newSection/getNewSections',
    }),
  },
}
</script>
