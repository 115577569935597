<template>
  <div style="margin-top: 31px;">
    <!-- For title -->
    <div class="columns is-multiline">
      <div class="column is-10 content-title">
        <h1 class="content-title__text">Settings</h1>
      </div>
      <div class="column is-2"></div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-10 content-title">
        <h1 class="content-title__text" style="font-size: 18px;">Survilance</h1>
      </div>
      <div class="column is-2"></div>
    </div>

    <!-- For detail -->
    <div class="columns is-multiline separator-line">
      <!-- For interval screen capture -->
      <div class="column is-3" style="height: 45px;">
        <b>Interval Screen Capture</b>
      </div>
      <div class="column is-9">
        <template v-if="$route.name != 'showNewAssessment'">
          <div class="columns is-12">
            <div class="column is-10">
              <ValidationProvider
                :vid="`screencapturetime`"
                :rules="`required|minsecond:1`"
                name="Interval Screen Capture"
                v-slot="{ errors }"
              >
                <b-field :type="errors.length > 0 ? 'is-danger' : null">
                  <b-input
                    v-model="data.screenCaptureTime"
                    type="number"
                    min="1"
                    placeholder="Range In Second"
                  ></b-input>
                </b-field>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="column is-2">Seconds</div>
          </div>
        </template>
        <template v-else>
          {{ data.screenCaptureTime }}
        </template>
      </div>

      <!-- For interval screen record -->
      <div class="column is-3" style="height: 45px;">
        <b>Interval Candidate's Photo</b>
      </div>
      <div class="column is-9">
        <template v-if="$route.name != 'showNewAssessment'">
          <div class="columns is-12">
            <div class="column is-10">
              <ValidationProvider
                :vid="`screenshottime`"
                :rules="`required|minsecond:1`"
                name="Inteval Candidate's Photo"
                v-slot="{ errors }"
              >
                <b-field :type="errors.length > 0 ? 'is-danger' : null">
                  <b-input
                    v-model="data.screenShotTime"
                    type="number"
                    min="1"
                    placeholder="Range In Second"
                  ></b-input>
                </b-field>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="column is-2">Seconds</div>
          </div>
        </template>
        <template v-else>
          {{ data.screenCaptureTime }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'assessment-survilance',
  props: {
    data: {
      default: () => {},
    },
  },
}
</script>
